export const WhatWeDoContent = {
    section2Title: 'Mission Statement',
    section2Heading: 'Making my vision my mission',
    section2TextBlockA: 'I am the CEO and Founder of Alliance Living Housing Association. During the pandemic, people’s mental health and emotional wellbeing was at its record lowest, this then led on to further suffering when the financial impact started more than ever affecting people that were already struggling. Homelessness has spiked as a result. Sadly, most of this just keeps spinning around causing almost like a ripple effect as most of these circumstances lead to people making bad choices out of pure despair. Unfortunately, people don’t always get the help they need or deserve. This did not sit right with me so I then started to think of ways that I could possibly help and give back to the community. Alliance was born.',
    section2TextBlockB: 'After much thought and careful consideration, I decided that I would like to invest in and start up a service that supported vulnerable adults in accessing the help and support they need and require to get themselves back on track, alongside a roof over their heads that they can call home. I take deep pride and thought into making our houses a home. I like to think of our association as a family. We help our service users to get back on their feet and to venture back out into society once they feel ready to. We help our service users by supporting them in all aspects of mental balance and emotional health and guiding them with making the right decisions. I had my vision now I am turning it into my mission. There’s nothing more rewarding than giving back and making a difference in the lives of people in this community.',
    section2Name: 'Kitty Woodley',
    section2JobTitle: 'CEO & Founder',

    section1MiniTitle: 'Tailored Support',
    section1Title: 'High Quality Accomodations',
    section1Subheading: 'We are about high quality accommodation, tailored support and helping people be their best selves. We provide a different approach to care and support, but ask for a lot of effort in return.',
    section1TextBlockA: 'Alliance Living Housing Association is committed to a personalised and recovery-oriented approach. Alliance creates conditions in which people can feel empowered to grow to realise their potential and develop their wellbeing. Alliance uses a whole person approach which is sensitive to, and values the diversity of a person’s individual background and needs.',
    section1TextBlockBHeading: 'Supporting People to achieve personal outcomes.',
    section1TextBlockB: 'Alliance seeks to promote independence, to improve quality of life and boost social inclusion. Alliance supports people on their individual journeys, enabling them to identify their needs and aspirations, and drive decisions regarding their safety and wellbeing.',
    section1TextBlockCHeading: 'Hotel Quality Rooms',
    section1TextBlockC: 'All our rooms are hotel quality and many are en suite. All facilities are modern and clean. It is our belief that the quality of our accommodation demonstrates a respect for the clients and sets a tone of mutual responsibility for the living and working environment that we all share.',
    section1TextBlockDHeading: 'High Calibre Level Of Support',
    section1TextBlockD: 'Our support workers bring a breadth of experience that helps and informs the work that they do. Each client is treated as an individual and the care provided is unique to each person. We want to help people recover, demonstrate that our clients are valued and respected and prepare each individual for the steps they need to take to achieve their goals',

    section3Title: 'Memberships & Registrations'
}