export const RARContent = {
    title: "Report A Repair",
    
    heading: "Report Repair, Mould and Damp issues",
    TextBlockA: "We can help you keep your home in good condition through our repairs service for tenants and information about simple fixes and maintenance. The speed in which we respond to your repair will be dependent on what’s wrong and how urgent the repair is.",
    
    heading2: "How can I report a repair?",
    bulletpoint2a: "Call our Housing Services Team",
    bulletpoint2b: "Email us at ",
    bulletpoint2bLink: "repairs@allianceliving.org.uk",
    bulletpoint2c: "Write to us at: Parkgate House, 195 Stratford Rd, Shirley, Solihull B90 3AU",
    bulletpoint2d: "Use the form on this page",

    heading3: "When you report a repair we need to know:",
    bulletpoint3a: "Name & Address",
    bulletpoint3b: "Contact info e.g. telephone number or email",
    bulletpoint3c: "A detailed description of the repair i.e. what needs repairing and where the repair is",
    bulletpoint3d: "Confirmation of a convenient time for us to carry out the repair."
}