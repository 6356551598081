

const WhoWeAre = ({coverIMG, section1Title, section1TextBlockA, section1TextBlockB, section1TextBlockC }) => {
    return(
        <div className="overflow-hidden lg:p-2 lg:m-2 ">
        <div className="mx-auto max-w-7xl lg:px-8">
          <div className="grid max-w-screen grid-cols-1 gap-x-32 2xl:gap-x-48 gap-y-10 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <img
              src={coverIMG}
              alt="Product screenshot"
              className="lg:rounded-xl shadow-2xl lg:max-w-xl xl:max-w-2xl 2xl:max-w-3xl md:-ml-5 lg:-ml-10 2xl:-ml-24 my-auto"
            />
            <div className="lg:pr-2 lg:pt-4 mb-10 ml-4">
              <div className="lg:max-w-xl">
                <dl className="mt-0 max-w-screen space-y-3 text-base leading-7 text-slate-500 lg:max-w-none">
                  <h2 className="mb-6 text-3xl font-bold tracking-tight text-cyan-600 sm:text-4xl">
                    {section1Title}
                  </h2>
                  <div className="relative lg:pl-1">
                    <p className="inline">
                      {section1TextBlockA}
                    </p>
                  </div>
                  <div className="relative lg:pl-1">
                    <dd className="inline">
                      {section1TextBlockB}
                    </dd>
                  </div>
                  <div className="relative lg:pl-1">
                    <dd className="inline">
                      {section1TextBlockC}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default WhoWeAre;