import { PrivacyPolicyContent } from "../Content/PrivacyPolicyContent";

export default function PrivacyPolicy() {
  return (
    <div>
      <div class="justify-center items-center flex-col mt-2 lg:mt-8 min-[2000px]:-mt-2 ">
        <div class="w-full h-full bg-PrivacyPolicyBanner bg-cover bg-center bg-fixed ">
          <div
            class="w-full h-full flex justify-center items-center backdrop-brightness-75 backdrop-blur-[2px]">
            <div className="mx-auto place-self-center lg:col-span-7 p-4 my-auto text-center">
              <h1 className="py-52 lg:py-72 max-w-2xl text-7xl font-bold tracking-tight leading-none md:text-5xl xl:text-6xl text-white">
              {PrivacyPolicyContent.title}
              </h1>
            </div>
          </div>
        </div>
      </div>


      <div className="w-full bg-gradient-to-t from-white to-slate-100 mb-48 pt-4 p-4 md:pt-10 lg:px-64">

        <h1 className="mt-5 text-xl font-bold tracking-tight text-cyan-500 sm:text-2xl">
          {PrivacyPolicyContent.heading1}
        </h1>
        <p>
          {PrivacyPolicyContent.TextBlockA}
        </p>

        <h1 className="mt-5 text-xl font-bold tracking-tight text-cyan-500 sm:text-2xl">
          {PrivacyPolicyContent.heading2}
        </h1>
        <p>
          {PrivacyPolicyContent.TextBlockB}
        </p>

        <h1 className="mt-5 text-xl font-bold tracking-tight text-cyan-500 sm:text-2xl">
          {PrivacyPolicyContent.heading3}
        </h1>
        <p>
          {PrivacyPolicyContent.TextBlockC}
        </p>
      </div>
    </div>
  );
}
