// import { useState } from "react";
import Accordion from "../components/Accordion";

import coverIMG from "../imgs/Home/section1IMG.jpg";
import referIMG from "../imgs/Home/refer.png";
import volunteerIMG from "../imgs/Home/volunteer.jpg";
import donationsIMG from "../imgs/Home/donations.jpg";
import accordionData from "../Content/accordionData";
import { HomeContent } from "../Content/HomeContent";
import WhoWeAre from "../components/WhoWeAre";

export default function Home() {
  return (

    // Hero Section
    <div>

<div class="h-[65vh] lg:h-[80vh] mt-20 lg:mt-24">
        <div class=" w-full h-full bg-hero bg-cover bg-center ">
          <div class="w-full h-full flex bg-cyan-700/60 backdrop-brightness-75 backdrop-blur">
            <div className=" place-self-center mx-auto p-6 lg:p-16">
              <div className="animate-fade-in-up ">
            {/* <svg id="Layer_1" data-name="Layer 1" class="h-12 md:h-20 mr-4 lg:mr-6 my-auto w-auto flex-no-shrink fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 492.35 520"><defs></defs><polygon class="cls-1" points="0 243.49 245.79 0 492.35 243.49 492.35 520 447.03 520 447.03 260 247.9 60.87 46.61 262.16 46.61 409.39 357.16 409.39 357.16 453.18 0 453.18 0 243.49"/><polygon class="cls-1" points="114.45 383.28 71.43 383.28 71.43 270.37 247.33 94.48 426.29 273.44 426.29 520 0 520 0 470.84 379.44 470.84 379.44 284.19 246.17 156.69 112.91 285.73 114.45 383.28"/></svg> */}

              <div className=" items-center w-full lg:w-3/5">
              <h1 className="font-bold leading-snug tracking-tight text-white text-4xl  lg:leading-tight xl:text-6xl xl:leading-tight">
              Providing housing & support for those in need.
            </h1>
              <p className=" text-slate-200 text-xs lg:text-lg mt-4 hidden md:block">
              We offer high quality accomodation for people in need. If you are in need of supported housing, complete our referral form and a member of staff will contact you</p>
              <div className="mt-10">
              <a
                href="/referral"
                rel="noopener"
                className="px-3 py-3 md:px-4 md:py-4 text-lg font-medium text-center text-white bg-cyan-500 rounded-lg hover:bg-cyan-600 ">
                Make A Referral
              </a>
              
            </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div className="overflow-hidden text-center bg-cyan-600">
        <div className="mx-auto max-w-4xl lg:p-2 ">
          <div className=" max-w-screen lg:mx-0 lg:max-w-none ">
            <div className="lg:pr-2 pt-4 mb-10 ">
              <div className="p-4">
                <dl className="max-w-screen space-y-2 text-base leading-7 text-slate-200 lg:max-w-none">
                  <h2 className="mb-6 text-3xl font-bold tracking-tight text-slate-50 sm:text-4xl">
                    Coronavirus Notice:
                  </h2>
                  <div className="relative lg:pl-1">
                    <p className="inline">
                    The safety and well-being of our tenants, staff and partners is our number one priority and we understand that many of you will have growing concerns around the COVID-19 pandemic.
                    </p>
                  </div>
                  <div className="relative lg:pl-1">
                    <dd className="inline">
                    Alliance Living are taking measures to minimise the disruption of our services and the potential spread of the virus to our tenants, staff and partners. We are constantly monitoring this ever-changing situation and will continue to follow the latest advice and guidance as provided by the Government.
                    </dd>
                  </div>

                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* Who We Are */}
      <WhoWeAre coverIMG={coverIMG} section1Title={HomeContent.section1Title} section1TextBlockA={HomeContent.section1TextBlockA} section1TextBlockB={HomeContent.section1TextBlockB} section1TextBlockC={HomeContent.section1TextBlockC} />

      
      {/* Parallax */}
      <div class="justify-center items-center flex-col mt-2 lg:mt-8 min-[2000px]:-mt-2 ">
        <div class="mb-6 md:mb-8 w-full h-full bg-HomeBanner bg-cover bg-center bg-fixed ">
          <div
            class="w-full h-full flex justify-center items-center backdrop-brightness-50 backdrop-blur-[4px]">
            <div className="mx-auto place-self-center lg:col-span-7 p-4 my-auto text-center">
              <h1 className=" py-52 lg:py-60 max-w-4xl text-7xl font-bold tracking-tight leading-none md:text-5xl xl:text-6xl text-white">
                {HomeContent.section2Heading}
              </h1>
            </div>
          </div>
        </div>
      </div>



      {/* How You Can Help */}
      <div className="overflow-hidden p-10">
        <div className="mx-auto max-w-7xl ">
          <div class=" max-w-full ">
            <div class="-p-1">
              <div className="mx-auto items-center grid grid-cols-1 gap-x-32 gap-y-10 sm:gap-y-20 lg:mx-0 lg:max-w-screen lg:grid-cols-3">
                <div class="w-auto">
                  <img
                    class="rounded-full mx-auto w-auto h-72"
                    src={donationsIMG}
                    alt=""
                  />
                  <div class="p-3">
                    <h5 class="my-4 text-3xl font-bold tracking-tight text-cyan-600 text-center">
                      {HomeContent.section3HeadingA}
                    </h5>

                    <p class="mb-3 md:p-10 lg:p-0 font-normal text-slate-700 ">
                      {HomeContent.section3TextBlockA}
                    </p>
                  </div>
                </div>

                <div class="min-w-fit w-auto">
                  <div href="#">
                    <img
                      class="rounded-full mx-auto w-auto h-72"
                      src={volunteerIMG}
                      alt=""
                    />
                  </div>
                  <div class="p-4">
                    <h5 class="mb-4 text-3xl font-bold tracking-tight text-cyan-600 text-center">
                      {HomeContent.section3HeadingB}
                    </h5>

                    <p class="mb-3 font-normal text-slate-700 ">
                      {HomeContent.section3TextBlockB}
                    </p>
                  </div>
                </div>

                <div class="max-w-fit w-auto">
                  <div href="#">
                    <img
                      class="rounded-full mx-auto w-auto h-72"
                      src={referIMG}
                      alt=""
                    />
                  </div>
                  <div class="p-5">
                    <div>
                      <h5 class="mb-4 text-3xl font-bold tracking-tight text-cyan-600 text-center">
                        {HomeContent.section3HeadingC}
                      </h5>
                    </div>
                    <p class="mb-3 font-normal text-slate-700 ">
                      {HomeContent.section3TextBlockC}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center">
              <a
                href="/contact-us"
                class="inline-flex items-center m-4 px-4 py-3  font-medium text-center text-white bg-sky-700 rounded-lg hover:bg-sky-800 focus:ring-4 focus:outline-none focus:ring-sky-300 dark:bg-sky-600 dark:hover:bg-sky-700 dark:focus:ring-sky-800"
              >
                Get In Touch!
              </a>
            </div>
          </div>
        </div>
      </div>


     {/* Did You Know Section */}
      <div className="bg-cyan-600">
      <div className="overflow-hidden lg:py-6 lg:m-8 pr-8 lg-m-8 sm:py-12 sm:mt-16 ">
        <h1 className="p-8 text-3xl font-bold tracking-tight text-slate-50 sm:text-4xl text-center">
          Frequently Asked Questions
        </h1>
        <div className="mx-auto max-w-fit lg:max-w-6xl ">
          <div className="accordion">
            {accordionData.map(({ title, content }) => (
              <Accordion title={title} content={content} />
            ))}
          </div>
        </div>
      </div>
      </div>



      
              {/* Testimonials */}
      <div className="p-0">
      <div class="container my-24 mx-auto md:px-6 ">
  <section class="mb-32 text-center">
    <h2 class="mb-12 text-4xl font-bold text-cyan-600">Testimonials</h2>

    <div class="grid gap-x-6 md:grid-cols-3 lg:gap-x-12">

      <a href="https://g.co/kgs/pJht5W" target="_blank" rel="noopener noreferrer">
      <div class="mb-12 md:mb-0 p-4" >
        <h5 class="mb-2 text-lg font-bold text-cyan-900">{HomeContent.section5NameA}</h5>
        <p class="mb-4">
        {HomeContent.section5MessageA}
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="inline-block w-6">
            <path fill="currentColor"
              d="M580 556h160V396H580v160Zm-360 0h160V396H220v160Zm406 220 80-160H520V336h280v288l-76 152h-98Zm-360 0 80-160H160V336h280v288l-76 152h-98Zm34-300Zm360 0Z" />
          </svg>
        </p>
        <ul class="mb-0 flex justify-center ">
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning ">
              <path fill="#e37400"
                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
            </svg>
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
              <path fill="#e37400"
                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
            </svg>
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
              <path fill="#e37400"
                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
            </svg>
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
              <path fill="#e37400"
                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
            </svg>
          </li>
          <li>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
              <path fill="#e37400"
                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
            </svg>
            {/* Half Star
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
              <path fill="currentColor"
                d="m480 757 157 95-42-178 138-120-182-16-71-168v387ZM233 976l65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
            </svg> */}
          </li>
        </ul>
      </div>
      </a>

      <a href="https://g.co/kgs/uEHJQz" target="_blank" rel="noopener noreferrer">
      <div class="mb-12 md:mb-0 p-4">
        <h5 class="mb-2 text-lg font-bold text-cyan-900">{HomeContent.section5NameB}</h5>
        <p class="mb-4">
        {HomeContent.section5MessageB}
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="inline-block w-6">
            <path fill="currentColor"
              d="M580 556h160V396H580v160Zm-360 0h160V396H220v160Zm406 220 80-160H520V336h280v288l-76 152h-98Zm-360 0 80-160H160V336h280v288l-76 152h-98Zm34-300Zm360 0Z" />
          </svg>
        </p>
        <ul class="mb-0 flex justify-center">
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
              <path fill="#e37400"
                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
            </svg>
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
              <path fill="#e37400"
                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
            </svg>
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
              <path fill="#e37400"
                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
            </svg>
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
              <path fill="#e37400"
                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
            </svg>
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
              <path fill="#e37400"
                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
            </svg>
          </li>
        </ul>
      </div>
      </a>


      <a href="https://g.co/kgs/Qi8vMx" target="_blank" rel="noopener noreferrer">
      <div class="mb-0 p-4">
        <h5 class="mb-2 text-lg font-bold text-cyan-900">{HomeContent.section5NameC}</h5>
        <p class="mb-4">
        {HomeContent.section5MessageC}
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="inline-block w-6">
            <path fill="currentColor"
              d="M580 556h160V396H580v160Zm-360 0h160V396H220v160Zm406 220 80-160H520V336h280v288l-76 152h-98Zm-360 0 80-160H160V336h280v288l-76 152h-98Zm34-300Zm360 0Z" />
          </svg>
        </p>
        <ul class="mb-0 flex justify-center">
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
              <path fill="#e37400"
                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
            </svg>
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
              <path fill="#e37400"
                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
            </svg>
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
              <path fill="#e37400"
                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
            </svg>
          </li>
          <li>
            {/* Full Star */}
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
              <path fill="#e37400"
                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
            </svg>
          </li>
          <li>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
              <path fill="#e37400"
                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
            </svg>
            {/* Empty Star
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
              <path fill="currentColor"
                d="m323 851 157-94 157 95-42-178 138-120-182-16-71-168-71 167-182 16 138 120-42 178Zm-90 125 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-355Z" />
            </svg> */}
          </li>
        </ul>
      </div>
      </a>
    </div>
    
  </section>
</div>
</div>
    </div>
  );
}
