export const ForLandlordsContent = {
    title: "For Landlords",
    heading: "What We Offer",
    TextBlockA: "Our ability to provide homes for our tenants depends on reliable landlords.",
    TextBlockB: "As landlords, you can be confident that we will rent out space to tenants, handle all housing management and health and safety-related tasks, and ensure frequent visits to your property to ensure it is kept in good condition.",
    TextBlockC: "If you have a property and want to invest in your community contact us to hear more about how we can work together.",

    subheading: "Leasing With Us Will Mean: ",
    bulletpointA: "No letting/agency fees",
    bulletpointB: "Lease Documentation work done by us",
    bulletpointC: "Guaranteed rental income",
    bulletpointD: "Day to Day Housing management",
    bulletpointE: "Regular checks on properties",
    bulletpointF: "You will be helping vulnerable people to obtain accomadtion",
    bulletpointG: "Day to Day repairs",

}