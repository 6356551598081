// import ReferralForm from "../../Referral-Form.docx";
import { ReferralContent } from "../../Content/ContactUs/ReferralContent";
export default function Referrals() {
  const FILE_LOCAL_URL = "http://localhost:3000/referral-form.zip"
  const FILE_HOST_URL = "https://www.alliancehousing.org.uk/referral-form.zip"
  const downloadFileatURL=(url)=>{
    const fileName = url.split('/').pop()
    const aTag = document.createElement('a')
    aTag.href=url
    aTag.setAttribute('download', fileName);
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
  }
  return (
    <div>

      {/* Parallax */}
      <div class="justify-center items-center flex-col mt-2 lg:mt-8 min-[2000px]:-mt-2 ">
        <div class="w-full h-full bg-ReferralsBanner bg-cover bg-center bg-fixed ">
          <div
            class="w-full h-full flex justify-center items-center backdrop-brightness-75 backdrop-blur-[4px]">
            <div className="mx-auto place-self-center lg:col-span-7 p-4 my-auto text-center">
              <h1 className="py-52 lg:py-72 max-w-2xl text-7xl font-bold tracking-tight leading-none md:text-5xl xl:text-6xl text-white">
              {ReferralContent.title}
              </h1>
            </div>
          </div>
        </div>
      </div>


      <h1 className="pt-10 text-4xl font-bold tracking-tight text-gray-900 text-center">
      {ReferralContent.heading}
      </h1>
      <div class="text-center">
        <span class="inline-block w-1 h-1 rounded-full bg-cyan-500 ml-1"></span>
        <span class="inline-block w-3 h-1 rounded-full bg-cyan-500 ml-1"></span>
        <span class="inline-block w-40 h-1 rounded-full bg-cyan-500 ml-1"></span>
        <span class="inline-block w-3 h-1 rounded-full bg-cyan-500 ml-1"></span>
        <span class="inline-block w-1 h-1 rounded-full bg-cyan-500 ml-1"></span>
      </div>

      <div className="overflow-hidden lg:p-12 lg:m-2 ">
        <div className="mx-auto max-w-7xl lg:px-8">
          <div className="grid max-w-screen grid-cols-1 gap-x-32 2xl:gap-x-48 gap-y-2 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">

              <div className="lg:max-w-xl mb-4 lg:mb-32">
                <dl className="mt-0 max-w-screen space-y-3 text-base leading-7 text-slate-500 lg:max-w-none p-4 md:p-0">

                  <div className="relative lg:pl-1">
                    {/* <h3 className="font-semibold"></h3> */}
                    <p>
                      {ReferralContent.TextBlockA}
                    </p>
                    <p className="font-semibold mt-4">{ReferralContent.Addressline1}</p>
                    <p>{ReferralContent.Addressline2}</p>
                    <p>{ReferralContent.Addressline3}</p>
                    <p>{ReferralContent.Addressline4}</p>
                    <br></br>
                    <span>Telephone: </span>
                    <a href={"tel: ".concat(ReferralContent.Telephone)} class="mr-4 hover:underline md:mr-4 font-thin text-sky-500">{ReferralContent.Telephone}</a>
                    <br></br>
                    <span>Email: </span>
                    <a
                      href={"mailto: ".concat(ReferralContent.Email)}
                      class="hover:underline font-thin text-sky-500"
                    >
                      {ReferralContent.Email}
                    </a>
                  </div>
                  <div className="relative lg:pl-1">
                    <dd className="inline font-bold">
                     
                    </dd>
                  </div>
                  
                </dl>
              </div>

            <div className=" pt-0 mb-4 text-slate-500 p-4">
          <p>
            {ReferralContent.TextBlockB}
          </p>
          <p className="mt-4">
            {ReferralContent.TextBlockC}
          </p>

          {/* Download */}
          {/* <a href="https://drive.google.com/file/d/1YjaICZjDxSEOABWFQHN4w2T3H-t35uDz/view?usp=drive_link" download="Referral Form" target="_blank" rel="noopener noreferrer"
            class="inline-flex items-center mt-5 py-4 px-6 font-medium text-center text-white bg-cyan-600 rounded-lg hover:bg-cyan-700 focus:ring-4 focus:outline-none focus:ring-cyan-300"
          >
            REFERRAL FORM
          </a> */}
          
                    <button onClick={()=>{downloadFileatURL(FILE_HOST_URL)}}  download="Referral Form" target="_blank" rel="noopener noreferrer"
            class="inline-flex items-center mt-5 py-4 px-6 font-medium text-center text-white bg-cyan-600 rounded-lg hover:bg-cyan-700 focus:ring-4 focus:outline-none focus:ring-cyan-300"
          >
            REFERRAL FORM
          </button>
        </div>
          </div>
        </div>
      </div>

    </div>
  );
}
