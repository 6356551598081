export const menuItems = [
  {

    title: 'About Us',
    url: '',
    submenu: [
      {
        title: 'What We Do',
        url: 'what-we-do',
      },
      {
        title: 'Our Staff',
        url: 'team',
      },
      {
        title: 'Testimonials',
        url: 'testimonials',
      }

    ],
  },
  {
    title: 'Services',
    url: '/services',
    submenu: [
      {
        title: 'Supported Housing',
        url: 'supported-housing',
      },
      {

        title: 'Repairs',
        url: 'repairs',
      },
    ],
  },
  {
    title: 'Work With Us',
    url: '',
    submenu: [
      {
        title: 'Careers',
        url: 'careers',
      },
      {
        title: 'For Landlords',
        url: 'landlords',
      },
    ],
  },
  {
    title: 'Contact Us',
    url: '',
    submenu: [
      {
        title: 'Contact Us',
        url: 'contact-us',
      },
      {
        title: 'Referral Form',
        url: 'referral',
      },
      {
        title: 'Report A Repair',
        url: 'report-a-repair',
      }
    ],
  },
  {
    title: 'Privacy Policy',
    url: '/policy',
  },
];