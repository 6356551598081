export const TestimonialContent = {
    title: "Testimonials",
    subheading: "What people are saying.",
    name1 : 'DANIEL MONTAGUE',
    desc1 : 'I have been a tenant at their Old Lode Lane property for around 15 months now, and have been housed here more or less since the beginning of this property as Supported Housing. I can honestly say that I have always felt like the staff have my back; Are willing to go above and beyond to help me and ensure that I am safe and feel that way. Having been in several different organisations properties, the quality of Alliance Living is really unparalleled. Each room has an ensuite shower and toilet. The communal areas are extremely well appointed...',

    name2 : 'MATASSA ROLLASON',
    desc2 : 'Myself and my partner have been living in supported accommodation here at Alliance Living since October 2022, the team here have been extremely supportive to us both, they have gone above and beyond for us, and continue to do so on a daily basis, especially as we found out whilst living here, that my partner has been diagnosed with terminal cancer, Ali, Kitty, Nidia, Charlotte, Katie and all the other staff are always here for us including ensuring we get to hospital appointments and other appointments.',

    name3 : 'NESSA ALI',
    desc3 : 'I was referred to Alliance Living by Birmingham City Council homeless team and I have to say I have had such a positive experience with Alliance. They put me in a womens only house and I was looked after with great care and consideration. I was feeling very low and suicidal and Alliance Living offered me great support and helped me live independent again. If only other organisation could take a leaf out of Alliance book. Well done to all staff including Lydia, Charlotte and Katie.',

    name4 : 'GRAHAM PARRY',
    desc4 : 'I have been living with Alliance for the past 6 months and I can not say a bad word about them. Nidia and Charlotte have been absolutely amazing with the help and support i have received. I am in such a good place mentally and this is all down to the the team at Alliance. Well done and keep up the good work.',

    name5 : 'SAM FARAJ',
    desc5 : 'The team was very helpful, they treated me like a family. The room is really nice and clean I have my own shower and toilet.',

    name6 : 'ABDULAZIZ DASA',
    desc6 : 'The team at Alliance Living helped me into a beautiful ensuite room, thank you all. Appreciate your service.'

}