
const Testimonial = ({name, content}) => {
    return (
        <div class="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
        <div class="w-full flex mb-4 items-center">

            <div class="flex-grow">
                <h6 class="font-bold text-sm uppercase text-cyan-600">{name}</h6>
            </div>
        </div>
        <div class="w-full">
            <p class="text-sm leading-tight"><span class="text-lg leading-none italic font-bold text-gray-400 mr-1">"</span>{content}<span class="text-lg leading-none italic font-bold text-gray-400 ml-1">"</span></p>
        </div>
    </div>
    )
}

export default Testimonial