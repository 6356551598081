import { WrenchScrewdriverIcon, WrenchIcon } from "@heroicons/react/20/solid";
import coverIMGRepair from "../../imgs/Repairs/section1IMG.jpg";
import { repairContent } from "../../Content/Services/RepairContent";
export default function Repairs() {

    return(
  <div>
      <div class="justify-center items-center flex-col mt-2 lg:mt-8 min-[2000px]:-mt-2 ">
        <div class="w-full h-full bg-RepairBanner bg-cover bg-center bg-fixed ">
          <div
            class="w-full h-full flex justify-center items-center backdrop-brightness-50 backdrop-blur-[2px]">
            <div className="mx-auto place-self-center lg:col-span-7 p-4 my-auto text-center">
              <h1 className="py-52 lg:py-72 max-w-2xl text-6xl font-semibold tracking-tight leading-none md:text-5xl xl:text-6xl text-white">
              {repairContent.title}
              </h1>
            </div>
          </div>
        </div>
      </div>

    <div className="relative isolate overflow-hidden bg-gradient-to-t from-white to-slate-100 py-10 sm:py-32 lg:overflow-visible lg:px-0">
        <div className="absolute inset-0 -z-10 overflow-hidden">
        </div>
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-14 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
          <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
            <div className="lg:pr-4 px-4 lg:px-0">
              <div className="lg:max-w-lg">

                <h1 className="mt-2 text-2xl font-bold tracking-tight text-cyan-600 sm:text-3xl">
                 {repairContent.heading}
                </h1>
                <p className="mt-6 text-xl leading-8 text-gray-700">
                  {repairContent.subheading}
                </p>
              </div>
            </div>
          </div>
          <div className="lg:-ml-12 lg:-mt-12 lg:p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
            <img
              className=" md:rounded-xl lg:max-w-2xl 2xl:max-w-3xl md:-ml-2 lg:-ml-10 mt-4w-[48rem] max-w-screen bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
              src={coverIMGRepair}
              alt=""
            />
          </div>
          <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8  lg:px-8">
            <div className="lg:pr-4 px-4 lg:px-0">
              <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              <h1 className="mt-2 text-xl font-bold tracking-tight text-cyan-600 sm:text-2xl">
                  {repairContent.Heading1}
                </h1>
                
                <ul className="pl-6 mt-8 space-y-8 text-gray-600">
                  <li className="flex gap-x-3">
                  <WrenchScrewdriverIcon
                      className="mt-1 h-5 w-5 flex-none text-cyan-700"
                      aria-hidden="true"
                    />
                      <strong className="font-semibold text-slate-700  sm:text-md">
                        {repairContent.bulletpoint1A}
                      </strong>
                  </li>
                  <li className="flex gap-x-3">
                  <WrenchScrewdriverIcon
                      className="mt-1 h-5 w-5 flex-none text-cyan-700"
                      aria-hidden="true"
                    />
                      <strong className="font-semibold text-slate-700   sm:text-md">
                      {repairContent.bulletpoint1B}
                      </strong>
                  </li>
                  <li className="flex gap-x-3">
                  <WrenchScrewdriverIcon
                      className="mt-1 h-5 w-5 flex-none text-cyan-700"
                      aria-hidden="true"
                    />
                      <strong className="font-semibold text-slate-700   sm:text-md">
                      {repairContent.bulletpoint1C}
                      </strong>
                  </li>
                  <li className="flex gap-x-3">
                  <WrenchScrewdriverIcon
                      className="mt-1 h-5 w-5 flex-none text-cyan-700"
                      aria-hidden="true"
                    />
                      <strong className="font-semibold text-slate-700   sm:text-md">
                      {repairContent.bulletpoint1D}
                      </strong>
                  </li>
                  <li className="flex gap-x-3">
                  <WrenchScrewdriverIcon
                      className="mt-1 h-5 w-5 flex-none text-cyan-700"
                      aria-hidden="true"
                    />
                      <strong className="font-semibold text-slate-700   sm:text-md">
                      {repairContent.bulletpoint1E}
                      </strong>
                  </li>
                  <li className="flex gap-x-3">
                  <WrenchScrewdriverIcon
                      className="mt-1 h-5 w-5 flex-none text-cyan-700"
                      aria-hidden="true"
                    />
                      <strong className="font-semibold text-slate-700  sm:text-md">
                      {repairContent.bulletpoint1F}
                      </strong>
                  </li>
                  <li className="flex gap-x-3">
                  <WrenchScrewdriverIcon
                      className="mt-1 h-5 w-5 flex-none text-cyan-700"
                      aria-hidden="true"
                    />
                      <strong className="font-semibold text-slate-700  sm:text-md">
                      {repairContent.bulletpoint1G}
                      </strong>
                  </li>
                </ul>
                <p className="mt-8">{repairContent.TextBlockA}</p>
                <h1 className="mt-6 text-xl font-bold tracking-tight text-cyan-600 sm:text-2xl">
                  {repairContent.Heading2}
                </h1>
                <ul className="pl-6 mt-8 space-y-8 text-gray-600">
                  <li className="flex gap-x-3">
                  <WrenchIcon
                      className="mt-1 h-5 w-5 flex-none text-cyan-700"
                      aria-hidden="true"
                    />
                      <strong className="font-semibold text-slate-700  sm:text-md">
                        {repairContent.bulletpoint2A}
                      </strong>
                  </li>
                  <li className="flex gap-x-3">
                  <WrenchIcon
                      className="mt-1 h-5 w-5 flex-none text-cyan-700"
                      aria-hidden="true"
                    />
                      <strong className="font-semibold text-slate-700   sm:text-md">
                      {repairContent.bulletpoint2B}
                      </strong>
                  </li>
                  <li className="flex gap-x-3">
                  <WrenchIcon
                      className="mt-1 h-5 w-5 flex-none text-cyan-700"
                      aria-hidden="true"
                    />
                      <strong className="font-semibold text-slate-700   sm:text-md">
                      {repairContent.bulletpoint2C}
                      </strong>
                  </li>
                  <li className="flex gap-x-3">
                  <WrenchIcon
                      className="mt-1 h-5 w-5 flex-none text-cyan-700"
                      aria-hidden="true"
                    />
                      <strong className="font-semibold text-slate-700   sm:text-md">
                      {repairContent.bulletpoint2D}
                      </strong>
                  </li>
                  <li className="flex gap-x-3">
                  <WrenchIcon
                      className="mt-1 h-5 w-5 flex-none text-cyan-700"
                      aria-hidden="true"
                    />
                      <strong className="font-semibold text-slate-700   sm:text-md">
                      {repairContent.bulletpoint2E}
                      </strong>
                  </li>
                </ul>
                <p className="mt-8">
                  {repairContent.TextBlockB} <a className="font-semibold underline" href="/report-a-repair">{repairContent.TextBlockBLink}</a> 
                </p>

                <p className="mt-6">
                {repairContent.TextBlockC}
                </p>
                
              </div>
            </div>
          </div>
        </div>
        </div>

  </div>
    )
}
