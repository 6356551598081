
import section1IMG from "../../imgs/Careers/section1IMG.jpg"
import { careersContent } from "../../Content/WorkWithUs/CareersContent";
export default function Careers() {


  return (
    <div>
            <div class="justify-center items-center flex-col mt-2 lg:mt-8 min-[2000px]:-mt-2 ">
        <div class="mb-6 md:mb-8 w-full h-full bg-CareersBanner bg-cover bg-center bg-fixed ">
          <div
            class="w-full h-full flex justify-center items-center backdrop-brightness-50 ">
            <div className="mx-auto place-self-center lg:col-span-7 p-4 my-auto text-center">
              <h1 className=" py-52 lg:py-72 max-w-2xl text-5xl font-bold tracking-tight leading-none md:text-5xl xl:text-6xl text-white">
              {careersContent.title}
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-hidden lg:p-12 lg:m-2 ">
        <div className="mx-auto max-w-7xl lg:px-2">
          <div className="grid max-w-screen grid-cols-1 gap-x-32 2xl:gap-x-48 gap-y-0 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className=" lg:pt-4 ">
              <div className="lg:max-w-xl">
                <dl className="mt-0 max-w-screen space-y-3 text-base leading-7 text-slate-500 lg:max-w-none p-4">
                <h2 className="mb-6 text-3xl font-bold tracking-tight text-cyan-600 sm:text-4xl">
                    {careersContent.heading}
                  </h2>
                  <div className="relative lg:pl-1">
                    {/* <h3 className="font-semibold"></h3> */}
                    <p className="inline">
                    {careersContent.TextBlockA}

                    </p>
                  </div>
                  <div className="relative lg:pl-1">
                    <dd className="inline">
                    {careersContent.TextBlockB}  </dd>
                  </div>
                  <div className="relative lg:pl-1">
                    <dd className="inline font-bold">
                      <span>{careersContent.TextBlockC} <a className="text-lg underline" href={careersContent.TextBlockCLink}>{careersContent.TextBlockCLinkText}</a></span>
                    </dd>       
                  </div>
                  <div className="relative lg:pl-1">
                    <dd className="inline font-bold">
                    <span>{careersContent.TextBlockD}<a className="underline text-lg" href={careersContent.TextBlockDemail}>{careersContent.TextBlockDemail}</a></span>
                    </dd>
                  </div>
                  <div className="relative lg:pl-1">
                    <dd className="inline font-bold">
                    {careersContent.TextBlockE} <a className="text-lg underline" href={careersContent.TextBlockEemail}>{careersContent.TextBlockEemail}</a></dd>
                  </div>
                </dl>
              </div>
            </div>
            <img
              src = {section1IMG}
              alt="Product screenshot"
              className="lg:rounded-xl shadow-xl lg:max-w-2xl 2xl:max-w-3xl lg:-ml-16  sm:my-auto"
            />
          </div>
        </div>
      </div>

</div>

  );
}
