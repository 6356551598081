import { TestimonialContent } from "../../Content/AboutUs/TestimonialContent"
import Testimonial from "../../components/Testimonial"
const Testimonials = () => {

    return(
        <div>
      <div class="justify-center items-center flex-col mt-2 lg:mt-8 min-[2000px]:-mt-2 ">
        <div class="mb-6 md:mb-8 w-full h-full bg-TestimonialBanner bg-cover bg-center bg-fixed ">
          <div
            class="w-full h-full flex justify-center items-center backdrop-brightness-50">
            <div className="mx-auto place-self-center lg:col-span-7 p-4 my-auto text-center">
              <h1 className=" py-52 lg:py-72 text-5xl font-bold tracking-tight leading-none md:text-5xl xl:text-6xl text-white">
              {TestimonialContent.title}
              </h1>
              
            </div>
          </div>
        </div>
      </div>
  
    <div class="min-w-screen flex items-center justify-center py-8 ">
    <div class="w-full bg-white px-10 text-gray-800">
        <div class="w-full max-w-6xl mx-auto">
            <div class="text-center max-w-xl mx-auto">
                <h1 class="text-6xl md:text-4xl font-bold mb-0 text-slate-00">{TestimonialContent.subheading}</h1>
                <div class="text-center mb-10">
                    <span class="inline-block w-1 h-1 rounded-full bg-cyan-500 ml-1"></span>
                    <span class="inline-block w-3 h-1 rounded-full bg-cyan-500 ml-1"></span>
                    <span class="inline-block w-40 h-1 rounded-full bg-cyan-500 ml-1"></span>
                    <span class="inline-block w-3 h-1 rounded-full bg-cyan-500 ml-1"></span>
                    <span class="inline-block w-1 h-1 rounded-full bg-cyan-500 ml-1"></span>
                </div>
            </div>
            <div class="-mx-10 md:flex items-start">

                <div class="px-3 md:w-1/2">

                    <Testimonial name={TestimonialContent.name1} content={TestimonialContent.desc1}/>
                    <Testimonial name={TestimonialContent.name2} content={TestimonialContent.desc2}/>
                </div>

                <div class="px-3 md:w-1/3">
                    
                <Testimonial name={TestimonialContent.name3} content={TestimonialContent.desc3}/>
                <Testimonial name={TestimonialContent.name4} content={TestimonialContent.desc4}/>
                </div>
                <div class="px-3 md:w-1/4">

                <Testimonial name={TestimonialContent.name5} content={TestimonialContent.desc5}/>
                <Testimonial name={TestimonialContent.name6} content={TestimonialContent.desc6}/>
                </div>
            </div>
        </div>
    </div>
</div>
</div>


    )
}

export default Testimonials