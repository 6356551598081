export const PrivacyPolicyContent = {
    title: "Privacy Policy",
    heading1: "Data Controller",
    TextBlockA: "The Data Controller for Alliance Living Housing Association is Kitty Woodley. To request information regarding the handling of your data, please email info@allianceliving.org and address your message to Kitty Woodley.",

    heading2: "Collected Personal Data",
    TextBlockB: "Data is collected in line with GDPR and subsequent legislation and is handled according to the rules set out therein. You have the right to request information on the data we hold about you. To do so, please email info@allianceliving.org.",

    heading3: "Purpose of collecting data",
    TextBlockC: "Your data is collected only for the purposes set out and is securely handled. All data is deleted after 30 days except for that of service users. No data is shared except where set out or for the purposes of law enforcement, crime prevention or compliance with Local Authority care and support requirements. For more information please email info@allianceliving.org."
}