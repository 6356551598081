export const ReferralContent = {
    title: "Referrals",

    heading: "Make A Referral",
    TextBlockA: "Making a referral is easy – download the form via the button on this page, and email or post it back to:",

    Addressline1: "Parkgate House",
    Addressline2: "195 Stratford Rd",
    Addressline3: "Shirley",
    Addressline4: "Solihull B90 3AU",

    Telephone: "07392803951",
    Email: "info@allianceliving.org",

    TextBlockB: "The referral will be assessed and a member of our team will get in touch with you with a decision.",
    TextBlockC: "If you have any questions, you can also call and speak to us.",

}