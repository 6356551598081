export const SHContent = {
    title: "Supported Housing",
    heading: "Supporting those in need",
    subtext: "We are about high quality accommodation, tailored support and helping people be their best selves.",

    TextBlockATitle: "What Is Supported Housing?",
    TextBlockA: "Our tenants have a place to call home thanks to the supportive housing we offer across England. It offers a foundation from which local authorities can provide care and support for adults with a wide range of needs while enabling them to maintain their independence within their local communities.",

    TextBlockBTitle: "Types Of Housing",
    TextBlockB: "Alliance Living offers two types of housing: self-contained and shared accommodation. Self-contained accommodation has all its own facilities. This means that each tenant has his or her own bedroom, bathroom, kitchen and living room. We also provide communal lounges within our supported living buildings and other facilities such as sensory rooms and whirlpools.",

    TextBlockCTitle: "Our properties",
    TextBlockC: "Alliance Living look to maximise the independence of our tenants and provide them with comfort, safety, independence and support. Almost all of our properties are newly built or recently converted buildings, and typically form part of a community of supported-living properties within a larger supported-living scheme. Our properties are located in safe, clean, residential neighbourhoods and are situated within close proximity to transport links, shops, statutory services and other amenities.",

    TextBlockD: "Please fill in our contact form or complete our referral form if you or a loved one are interested in supported living",

}