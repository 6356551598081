import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import WhatWeDo from "./pages/AboutUs/WhatWeDo";
import MeetTheTeam from "./pages/AboutUs/MeetTheTeam";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ContactUs from "./pages/ContactUs/ContactUs";
import Careers from "./pages/WorkWithUs/Careers";
import Testimonials from "./pages/AboutUs/Testimonials";
import Referrals from "./pages/ContactUs/Referrals";
import ReportARepair from "./pages/ContactUs/ReportARepair";
import SupportedHousing from "./pages/Services/SupportedHousing";
import Repairs from "./pages/Services/Repairs";
import Landlords from "./pages/WorkWithUs/Landlords";
// import ContentPull from "./pages/contentpull";

function App() {
  return (
    <div className="App ">
      <BrowserRouter>
        <Navbar />
        <div className="pages ">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/what-we-do" element={<WhatWeDo />} />
            <Route path="/team" element={<MeetTheTeam />} />
            <Route path="/policy" element={<PrivacyPolicy />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/testimonials" element={<Testimonials />} />
            <Route path="/referral" element={<Referrals />} />
            <Route path="/report-a-repair" element ={<ReportARepair />} />
            <Route path="/supported-housing" element={<SupportedHousing />} />
            <Route path="/repairs" element={<Repairs />} />
            <Route path="/landlords" element={<Landlords />} />
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
