
import section1IMG from "../../imgs/SupportedHousing/section1IMG.jpg";
import { SHContent } from "../../Content/Services/SupportedHousingContent";
export default function SupportedHousing() {

    return(
  <div>
          <div class="justify-center items-center flex-col mt-2 lg:mt-8 min-[2000px]:-mt-2 ">
        <div class="w-full h-full bg-SupportHousingBanner bg-cover bg-center bg-fixed ">
          <div
            class="w-full h-full flex justify-center items-center backdrop-brightness-75 backdrop-blur-[2px]">
            <div className="mx-auto place-self-center lg:col-span-7 p-4 my-auto text-center">
              <h1 className="py-52 lg:py-72 max-w-2xl text-6xl font-semibold tracking-tight leading-none md:text-5xl xl:text-6xl text-white">
              {SHContent.title}
              </h1>
            </div>
          </div>
        </div>
      </div>

    <div className="relative isolate overflow-hidden bg-gradient-to-t from-white to-slate-100 py-10 sm:py-32 lg:overflow-visible lg:px-0">
        <div className="absolute inset-0 -z-10 overflow-hidden">
        </div>
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-14 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
          <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
            <div className="lg:pr-4 px-4 lg:px-0">
              <div className="lg:max-w-lg">

                <h1 className="mt-2 text-2xl font-bold tracking-tight text-cyan-600 sm:text-3xl">
                  {SHContent.heading}
                </h1>
                <p className="mt-6 text-xl leading-8 text-gray-700">
                  {SHContent.subtext}
                </p>
              </div>
            </div>
          </div>
          <div className="lg:-ml-12 lg:-mt-12 lg:p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
            <img
              className=" md:rounded-xl lg:max-w-xl 2xl:max-w-2xl md:-ml-2 lg:ml-16 mt-4w-[48rem] max-w-screen bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
              src={section1IMG}
              alt=""
            />
          </div>
          <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8  lg:px-8">
            <div className="lg:pr-4 px-4 lg:px-0">
              <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              <h1 className="mt-2 text-xl font-bold tracking-tight text-cyan-600 sm:text-xl">
                  {SHContent.TextBlockATitle}
                </h1>
                <p className="mt-2">
                {SHContent.TextBlockA}</p>
                <ul className="mt-8 space-y-8 text-gray-600">
                  <li className="flex gap-x-3">

                    <span>
                      <strong className="font-semibold text-cyan-600  sm:text-xl">
                        {SHContent.TextBlockBTitle}
                      </strong>
                      <br></br>
                      {SHContent.TextBlockB}
                      </span>
                  </li>
                  <li className="flex gap-x-3">
                    <span>
                      <strong className="font-semibold text-cyan-600  sm:text-xl">
                      {SHContent.TextBlockCTitle}
                      </strong>
                      <br></br>{SHContent.TextBlockC} </span>
                  </li>
                
                </ul>
                <p className="mt-8">
                  {SHContent.TextBlockD}
                </p>
                
              </div>
            </div>
          </div>
        </div>
        </div>

  </div>
    )
}
