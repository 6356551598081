const accordionData = [
  {
    title: "Can I claim Housing Benefit?",
    content: `You may be eligible to apply for rent assistance if you are over 16 and live in a rented home. If you need more help applying for housing benefits, please get in touch with your housing support worker.`,
  },
  {
    title: "How do I pay my rent with Housing Benefit?",
    content: `If you claim Housing Benefit this can be paid directly to us, making things easier. Do remember though, if your Housing Benefit does not cover the full rent, you will have to pay the remaining amount.`,
  },
  {
    title: "I receive Housing Benefit, what do I do about rent increases?",
    content: `Please send a proof of the rent increase letter you receive to your Local Authority. We will also let the Housing Benefit department know about any changes.`,
  },
  {
    title: "Can I put up a satellite dish?",
    content: `It usually depends on the area you live in, and you would have to contact your local authority for advice. Once you have spoken to them and they have given you their approval, please telephone or write and request permission from your Housing Support Worker  who will contact you to discuss.`,
  },
  {
    title: "Do i need to insure my home?",
    content: `We insure the building, but strongly advise you to take out contents insurance. Unfortunately, as our policy does not cover your personal belongings, even if the damage is caused for example by a leak from another property, we would not be responsible for any damage done to your belongings.`,
  },
  {
    title: "What are the best ways to help those experiencing homelessness?",
    content: `The good news is that there are plenty of ways you can help. Perhaps you’d like to make a small donation, start a fundraising event or even volunteer
      Get in touch with professionals if you notice someone sleeping rough,
      donate food, volunteer with a homeless charity. 
`,
  },
];

export default accordionData;
