// import React, { useRef } from "react";
// import emailjs from "emailjs-com";
import { RARContent } from "../../Content/ContactUs/ReportARepairContent";
export default function ReportARepair() {
  // const form = useRef();

  // const sendEmail = (e) => {
  //   e.preventDefault();

  //   emailjs
  //     .sendForm(
  //       "service_pg1lwso",
  //       "template_qate1c5",
  //       form.current,
  //       "gWPZ08CTSMu2xemzy"
  //     )
  //     .then(
  //       (result) => {
  //         console.log(result.text);
  //       },
  //       (error) => {
  //         console.log(error.text);
  //       }
  //     );
  //   e.target.reset();
  // };

  return (
    <div>
      <div class="justify-center items-center flex-col mt-2 lg:mt-8 min-[2000px]:-mt-2 ">
        <div class="mb-6 md:mb-8 w-full h-full bg-ReportRepairBanner bg-cover bg-center bg-fixed ">
          <div
            class="w-full h-full flex justify-center items-center backdrop-brightness-50 backdrop-blur-[2px]">
            <div className="mx-auto place-self-center lg:col-span-7 p-4 my-auto text-center">
              <h1 className="py-52 lg:py-72 max-w-2xl text-7xl font-bold tracking-tight leading-none md:text-5xl xl:text-6xl text-white">
              {RARContent.title}
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-hidden lg:p-2 lg:m-2 ">
        <div className="mx-auto max-w-7xl lg:px-8">
          <div className="grid max-w-screen grid-cols-1 gap-x-24 2xl:gap-x-24 gap-y-10 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 py-8">
            <div className="lg:pt-4 ">
              <div className="lg:max-w-xl">
                <dl className="mt-0 max-w-screen space-y-3 text-base leading-7 text-slate-500 lg:max-w-none p-4">
                  <h2 className="mb-6 text-3xl font-bold tracking-tight text-cyan-600 sm:text-4xl">
                    {RARContent.heading}
                  </h2>
                  <div className="relative lg:pl-1">
                    <p className="inline">
                      {RARContent.TextBlockA}
                    </p>
                  </div>
                  <div className="relative lg:pl-1">
                    <dd className="inline">
                      <p className="font-semibold text-xl py-2">
                        {RARContent.heading2}
                      </p>
                      <ul className="list-disc list-outside px-8">
                        <li>{RARContent.bulletpoint2a}</li>
                        <li >{RARContent.bulletpoint2b}<a className="font-bold underline" href="mailto: referrals@allianceliving.org">{RARContent.bulletpoint2bLink}</a></li>
                        <li>{RARContent.bulletpoint2c}</li>
                        <li>{RARContent.bulletpoint2d}</li>
                      </ul>
                    </dd>
                  </div>
                  <div className="relative lg:pl-1">
                    <dd className="inline">
                      <p className="font-semibold text-xl py-2">
                        {RARContent.heading3}
                      </p>
                      <ul className="list-disc list-outside px-8">
                        <li>{RARContent.bulletpoint3a}</li>
                        <li>{RARContent.bulletpoint3b}</li>
                        <li>{RARContent.bulletpoint3c}</li>
                        <li>{RARContent.bulletpoint3d}</li>
                      </ul>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
            {/* <form ref={form} onSubmit={sendEmail} class="rounded px-5 pt-6 pb-8  mb-4"> */}
            <form action="https://formsubmit.co/589d7dbbbace65cd030a6d1c57351194" method="POST"class="rounded px-5 pt-6 pb-8 mb-4">

            <h2 className="mb-6 text-2xl font-bold tracking-tight text-cyan-600 sm:text-3xl">
              
            </h2>
              <div class="mb-4">
                <label
                  class="block text-cyan-500 text-sm font-bold mb-2"
                  for="name"
                >
                  First Name
                </label>
                <input
                  name="first_name"
                  class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="fname"
                  type="text"
                  placeholder="First Name"
                  required
                />
              </div>
              <div class="mb-4">
                <label
                  class="block text-cyan-500 text-sm font-bold mb-2"
                  for="name"
                >
                  Last Name
                </label>
                <input
                  name="last_name"
                  class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="lname"
                  type="text"
                  placeholder="Last Name"
                  required
                />
              </div>
              <div class="mb-6">
                <label
                  class="block text-cyan-500 text-sm font-bold mb-2"
                  for="email"
                >
                  Email
                </label>
                <input
                  name="user_email"
                  type="email"
                  class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="example@email.com"
                  required
                />
              </div>
              <div>
                <label
                  class="block text-cyan-500 text-sm font-bold mb-2"
                  for="email"
                >
                  Message
                </label>
                <textarea
                  name="message"
                  id="message"
                  rows="12"
                  class="block p-2.5 w-full  text-gray-900  rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Your Message"
                  required
                ></textarea>
              </div>
              <div class="flex items-center justify-between pt-4">
                <button
                  class="bg-cyan-500 hover:bg-cyan-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="submit"
                  value="Send"
                >
                  Submit
                </button>
              </div>
              <input type="hidden" name="_subject" value="Repair Reported"/>
            <input type="hidden" name="_captcha" value="false"/>
            <input type="hidden" name="_autoresponse" value="Hi there, we have recieved your message, we will try to get back to you as soon as possible."/>
            <input type="hidden" name="_template" value="table"/>
            {/* <input type="hidden" name="_next" value="https://www.alliancehousing.org.uk/"/> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
