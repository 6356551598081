export const HomeContent = {
  bannerTitle: "Alliance Living",
  bannerSubtitle: "Housing Association",

  section1Title: "Who We Are",

  section1TextBlockA: `We are a non-for profit organisation dedicated to
                    improving the lives of those at a disadvantage through
                    homelessness, mental health issues and addiction.`,

  section1TextBlockB: `All it takes to become homeless are a few poor decisions,
                    some bad luck or an unexpected change in circumstances.
                    Insecure housing, substance addiction, debt and mental
                    health problems are not easy to overcome, but Alliance
                    Living is designed to help people face these issues and
                    will work with the inidividual to create sustainable
                    solutions. We do this through a holistic approach of
                    support, housing provision and signposting; we work
                    alongside statutory and non-statutory organisations.`,

  section1TextBlockC: `Alliance gives people the opportunity to take stock of
                    their lives, deal with any issues they might have, and
                    often re-establish relationships with loved ones and the
                    community. We will support anybody with appropriate need
                    regardless of background.`,


  section2Heading: "Providing high quality support",


  section3HeadingA: "Donation",
  section3TextBlockA: `Our clients are usually on low incomes and donations of food, toiletries and good quality clothing are always greatly appreciated. Also donations of supermarket and High Street vouchers as well as money towards our work are welcome.`,
  section3HeadingB: "Volunteer",
  section3TextBlockB: `Whether it's working on property maintenance, mentoring our clients, cleaning, painting or many other tasks; we are always on the look out for fabulous folk with a heart for change.`,
  section3HeadingC: "Refer",
  section3TextBlockC: `Do you know somebody who is homeless or at risk of homelessness in Birmingham, Solihull or Reading? Ask them to reach out to us and we will assist them in their journey to housing security.`,

  // FOR SECTION 4 LOOK AT 'accordionData.js'

  
  section5NameA: 'Matassa Rollason',
  section5MessageA: `Myself and my partner have been living in supported accommodation here at Alliance Living since October 2022, the team here have been extremely supportive to us both, they have gone above and beyond for us, and continue to do so on a daily basis...`,
  section5LinkA: 'https://g.co/kgs/pJht5W',

  section5NameB: 'Daniel Montague',
  section5MessageB: `I have been a tenant at their Old Lode Lane property for around 15 months now, and have been housed here more or less since the beginning of this property as Supported Housing. I can honestly say that I have always felt like the staff have my back...`,
  section5LinkB: 'https://g.co/kgs/uEHJQz',
  
  section5NameC: 'Graham Parry',
  section5MessageC: `7 months ago I have been living with Alliance for the past 6 months and I can not say a bad word about them. Nidia and Charlotte have been absolutely amazing with the help and support i have received. I am in such a good place mentally...`,
  section5LinkC: 'https://g.co/kgs/5QHLGi'







};
