export const MTTContent = {
    title: "Meet The Team",

    TeamMember1Name: "Kitty",
    TeamMember1Title: "Founder & CEO",
    TeamMember1Desc: "Kitty is a passionate social campaigner with a heart for change. Kitty has a background in campaigning and looks after the business and advocacy side of Alliance.",

    TeamMember2Name: "Squishy",
    TeamMember2Title: "aka Eric",
    TeamMember2Desc: "There's nothing squishy about Eric. He keeps a close eye on the Alliance team and will not allow any fishy business. The Alliance mascot, he has a background in wet leasing, telling tales and fine art.",

    TeamMember3Name: "Charlotte",
    TeamMember3Title: "Trainee Support Worker",
    TeamMember3Desc: "Charlotte is passionate about support and uses the skills she developed in customer service to bring a person-centred approach to those for whom Alliance cares.",

    TeamMember4Name: "Katie",
    TeamMember4Title: "Trainee Support Worker",
    TeamMember4Desc: "Katie is a change maker; passionate about a person-centred approach, she brings a background in customer service combined with a passion for improving society through directly tackling social inequality.",

    TeamMember5Name: "Sahima",
    TeamMember5Title: "Support Worker",
    TeamMember5Desc: "Sam has previous experience in both the health sector and customer services. She has combined this experience with her self-driven heart to see the hurt healed and those she supports be the best version of themselves that they are able.",
    
    TeamMember6Name: "Ali",
    TeamMember6Title: "Housing Manager",
    TeamMember6Desc: "Ali has a background in property management and surveying and brings this, along with an infectious personality and huge work ethic to Alliance. Ali is a great combination of compassion, skill and a cool head;"
}
