
import { ForLandlordsContent } from "../../Content/WorkWithUs/ForLandlordsContent";
import section1IMG from "../../imgs/ForLandlords/section1IMG.jpg"
export default function Landlords() {
  return (
    <div>
            <div class="justify-center items-center flex-col mt-2 lg:mt-8 min-[2000px]:-mt-2 ">
        <div class="mb-6 md:mb-8 w-full h-full bg-ForLandlordsBanner bg-cover bg-center bg-fixed ">
          <div
            class="w-full h-full flex justify-center items-center backdrop-brightness-75 backdrop-blur-[2px]">
            <div className="mx-auto place-self-center lg:col-span-7 p-4 my-auto text-center">
              <h1 className=" py-52 lg:py-72 max-w-2xl text-7xl font-bold tracking-tight leading-none md:text-5xl xl:text-6xl text-white">
              {ForLandlordsContent.title}
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-hidden lg:p-12 lg:m-2 ">
        <div className="mx-auto max-w-7xl lg:px-8">
          <div className="grid max-w-screen grid-cols-1 gap-x-32 2xl:gap-x-48 gap-y-0 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className=" lg:pt-4 p-4">
              <div className="lg:max-w-xl">
                <dl className="mt-0 max-w-screen space-y-3 text-base leading-7 text-slate-500 lg:max-w-none ">
                <h2 className="mb-6 text-3xl font-bold tracking-tight text-cyan-600 sm:text-4xl">
                    {ForLandlordsContent.heading}
                  </h2>
                  <div className="relative lg:pl-1">
                    <p className="inline">
                    {ForLandlordsContent.TextBlockA}

                    </p>
                  </div>
                  <div className="relative lg:pl-1">
                    <dd className="inline">
                    {ForLandlordsContent.TextBlockB}  </dd>
                  </div>
                  <div className="relative lg:pl-1">
                    <dd className="inline">
                      <span>{ForLandlordsContent.TextBlockC}</span>
                    </dd>       
                  </div>
                  <div className="relative lg:pl-1">
                    <dd className="inline">
                    <p className="text-lg font-semibold">{ForLandlordsContent.subheading}</p>
                    <ul className="pl-10 mt-2 leading-6 text-gray-600 list-disc">
                      <li>{ForLandlordsContent.bulletpointA}</li>
                      <li>{ForLandlordsContent.bulletpointB}</li>
                      <li>{ForLandlordsContent.bulletpointC}</li>
                      <li>{ForLandlordsContent.bulletpointD}</li>
                      <li>{ForLandlordsContent.bulletpointE}</li>
                      <li>{ForLandlordsContent.bulletpointF}</li>
                      <li>{ForLandlordsContent.bulletpointG}</li>           
                    </ul>
                    </dd><br></br>   
                  </div>
                 
                </dl>
              </div>
            </div>
            <img
              src = {section1IMG}
              alt="Product screenshot"
              className="lg:rounded-xl shadow-xl lg:max-w-2xl 2xl:max-w-3xl lg:-ml-16 mb-10 sm:my-auto"
            />
          </div>
        </div>
      </div>

</div>

  );
}
