export const repairContent = {
    title: "Repairs",
    heading: "Keeping your home safe",
    subheading: "Alliance Living are responsible for carrying out certain repairs in your home.",

    Heading1: "Alliance Living are responsible for:",
    bulletpoint1A: "External Structure of the building",
    bulletpoint1B: "Doors & Window Frames",
    bulletpoint1C: "Pathways",
    bulletpoint1D: "Internal Walls",
    bulletpoint1E: "Baths, Basins, Toilets",
    bulletpoint1F: "Electrical Wiring",
    bulletpoint1G: "Gas & Water Pipes",

    TextBlockA: "However, you’re also responsible for fixing any damage that has been caused by yourself or any visitors to your home",

    Heading2: "You will be responsible for:",
    bulletpoint2A: "Light Bulbs",
    bulletpoint2B: "Toilet Seats",
    bulletpoint2C: "Appliances you have installed",
    bulletpoint2D: "Replacing Lock/Keys",
    bulletpoint2E: "Blocked Toilets & Sinks cause by misuse",

    TextBlockB: "If there is an issue with your house, please fill in our",
    TextBlockBLink: "Repair Form.",

    TextBlockC: "It is extremely important to us that our tenants feel safe and comfortable in their homes. As a registered social landlord, we are focused on maintaining our properties to the highest possible standards. We aim to provide an effective and responsive repairs service."
}