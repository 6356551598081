import kittyDP from '../../imgs/MeetTheTeam/TeamMember1.jpg'
import TeamMember2DP from '../../imgs/MeetTheTeam/TeamMember2.jpg'
import TeamMember3DP from '../../imgs/MeetTheTeam/TeamMember3.jpg'
import TeamMember4DP from '../../imgs/MeetTheTeam/TeamMember4.jpg'
import TeamMember5DP from '../../imgs/MeetTheTeam/TeamMember5.jpg'
import TeamMember6DP from '../../imgs/MeetTheTeam/TeamMember6.jpg'
import {MTTContent} from '../../Content/AboutUs/MeetTheTeamContent'

const MeetTheTeam = () => {
  return (


    // Parallax
    <div className="mb-4">
            <div class="justify-center items-center flex-col mt-2 lg:mt-8 min-[2000px]:-mt-2 ">
        <div class="w-full h-full bg-MeetTheTeamBanner bg-cover bg-center bg-fixed ">
          <div
            class="w-full h-full flex justify-center items-center backdrop-brightness-50 backdrop-blur-[2px]">
            <div className="mx-auto place-self-center lg:col-span-7 my-auto text-center ">
              <h1 className=" py-52 lg:py-72 max-w-2xl text-7xl font-bold tracking-tight leading-none md:text-5xl xl:text-6xl text-white">
              {MTTContent.title}
              </h1>
            </div>
          </div>
        </div>

      </div>
            <dh-component>
                <div className="w-full bg-gradient-to-t from-white to-slate-100 px-10 pt-20">
                    <div className="container mx-auto">
                        <div role="list" aria-label="The Team" className="lg:flex md:flex sm:flex items-center xl:justify-between flex-wrap md:justify-around sm:justify-around lg:justify-around">
                            <div role="listitem" className="xl:w-1/3 sm:w-3/4 md:w-2/5 relative mt-16 mb-32 sm:mb-24 xl:max-w-sm lg:w-2/5">
                                <div className="rounded overflow-hidden shadow-md bg-white">
                                    <div className="absolute -mt-20 w-full flex justify-center">
                                        <div className="h-32 w-32">
                                            <img src={kittyDP} alt="Kitty" className="rounded-full object-cover h-full w-full shadow-md" />
                                        </div>
                                    </div>
                                    <div className="px-6 mt-16">
                                        <h1 className="font-bold text-3xl text-center mb-1 text-cyan-500">{MTTContent.TeamMember1Name}</h1>
                                        <p className="text-gray-800 text-sm text-center">{MTTContent.TeamMember1Title}</p>
                                        <p className="text-center text-gray-600 text-base pt-3 font-normal">{MTTContent.TeamMember1Desc}</p>
                                        <div className="w-full flex justify-center pt-5 pb-5">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div role="listitem" className="xl:w-1/3 lg:mx-3 sm:w-3/4 md:w-2/5 relative mt-16 mb-32 sm:mb-24 xl:max-w-sm lg:w-2/5">
                                <div className="rounded overflow-hidden shadow-md bg-white">
                                    <div className="absolute -mt-20 w-full flex justify-center">
                                        <div className="h-32 w-32">
                                            <img src={TeamMember2DP} alt="Fish" className="rounded-full object-cover h-full w-full shadow-md" />
                                        </div>
                                    </div>
                                    <div className="px-6 mt-16">
                                        <h1 className="font-bold text-3xl text-center mb-1 text-cyan-500">{MTTContent.TeamMember2Name}</h1>
                                        <p className="text-gray-800 text-sm text-center">{MTTContent.TeamMember2Title}</p>
                                        <p className="text-center text-gray-600 text-base pt-3 font-normal">{MTTContent.TeamMember2Desc}.</p>
                                        <div className="w-full flex justify-center pt-5 pb-5">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div role="listitem" className="xl:w-1/3 sm:w-3/4 md:w-2/5 relative mt-16 mb-32 sm:mb-24 xl:max-w-sm lg:w-2/5">
                                <div className="rounded overflow-hidden shadow-md bg-white">
                                    <div className="absolute -mt-20 w-full flex justify-center">
                                        <div className="h-32 w-32">
                                            <img src={TeamMember3DP} alt="Charlotte" className="rounded-full object-cover h-full w-full shadow-md" />
                                        </div>
                                    </div>
                                    <div className="px-6 mt-16">
                                        <h1 className="font-bold text-3xl text-center mb-1 text-cyan-500">{MTTContent.TeamMember3Name}</h1>
                                        <p className="text-gray-800 text-sm text-center">{MTTContent.TeamMember3Title}</p>
                                        <p className="text-center text-gray-600 text-base pt-3 font-normal">{MTTContent.TeamMember3Desc}</p>
                                        <div className="w-full flex justify-center pt-5 pb-5">

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div role="listitem" className="xl:w-1/3 sm:w-3/4 md:w-2/5 relative mt-16 mb-32 sm:mb-24 xl:max-w-sm lg:w-2/5">
                                <div className="rounded overflow-hidden shadow-md bg-white">
                                    <div className="absolute -mt-20 w-full flex justify-center">
                                        <div className="h-32 w-32">
                                            <img src={TeamMember4DP} alt="Katie" className="rounded-full object-cover h-full w-full shadow-md" />
                                        </div>
                                    </div>
                                    <div className="px-6 mt-16">
                                        <h1 className="font-bold text-3xl text-center mb-1 text-cyan-500">{MTTContent.TeamMember4Name}</h1>
                                        <p className="text-gray-800 text-sm text-center">{MTTContent.TeamMember4Title}</p>
                                        <p className="text-center text-gray-600 text-base pt-3 font-normal">{MTTContent.TeamMember4Desc}</p>
                                        <div className="w-full flex justify-center pt-5 pb-5">

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div role="listitem" className="xl:w-1/3 sm:w-3/4 md:w-2/5 relative mt-16 mb-32 sm:mb-24 xl:max-w-sm lg:w-2/5">
                                <div className="rounded overflow-hidden shadow-md bg-white">
                                    <div className="absolute -mt-20 w-full flex justify-center">
                                        <div className="h-32 w-32">
                                            <img src={TeamMember5DP} alt="Sahima"  className="rounded-full object-cover h-full w-full shadow-md" />
                                        </div>
                                    </div>
                                    <div className="px-6 mt-16">
                                        <h1 className="font-bold text-3xl text-center mb-1 text-cyan-500">{MTTContent.TeamMember5Name}</h1>
                                        <p className="text-gray-800 text-sm text-center">{MTTContent.TeamMember5Title}</p>
                                        <p className="text-center text-gray-600 text-base pt-3 font-normal">{MTTContent.TeamMember5Desc}</p>
                                        <div className="w-full flex justify-center pt-5 pb-5">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div role="listitem" className="xl:w-1/3 sm:w-3/4 md:w-2/5 relative mt-16 mb-32 sm:mb-24 xl:max-w-sm lg:w-2/5">
                                <div className="rounded overflow-hidden shadow-md bg-white">
                                    <div className="absolute -mt-20 w-full flex justify-center">
                                        <div className="h-32 w-32">
                                            <img src={TeamMember6DP} alt="Ali"  className="rounded-full object-cover h-full w-full shadow-md" />
                                        </div>
                                    </div>
                                    <div className="px-6 mt-16">
                                        <h1 className="font-bold text-3xl text-center mb-1 text-cyan-500">{MTTContent.TeamMember6Name}</h1>
                                        <p className="text-gray-800 text-sm text-center">{MTTContent.TeamMember6Title}</p>
                                        <p className="text-center text-gray-600 text-base pt-3 font-normal">{MTTContent.TeamMember6Desc}</p>
                                        <div className="w-full flex justify-center pt-5 pb-5">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </dh-component>
        </div>
  )
};

export default MeetTheTeam;