const Footer = () => {
  return (
    <footer class="bg-cyan-600 rounded-t-xl shadow p-2">
      <div class="w-full mx-auto  p-4 md:flex md:items-center md:justify-between">
        <span class="text-sm sm:text-center text-slate-50">
          <a href="/" class="hover:underline font-bold">
            Alliance Living Housing Association
          </a>
        </span>
        <ul class="flex flex-wrap  mt-3 text-sm font-medium text-slate-50 sm:mt-0 ">
          <li>
            <a href="http://maps.google.com/?q=Parkgate House, 195 Stratford Rd, Shirley, Solihull B90 3AU" class="mr-4 hover:underline md:mr-4 ">
            Parkgate House, 195 Stratford Rd, Shirley, Solihull B90 3AU
            </a>
          </li>
          <li>
            <a href="tel: 07392803951" class="mr-4 hover:underline md:mr-4">
              Telephone: 07392803951
            </a>
          </li>
          <li>
            <a href="mailto: info@allianceliving.org" class="hover:underline mr-4 md:mr-4">
            info@allianceliving.org
            </a>
          </li>
          <p class="text-cyan-400 mt-3 text-sm font-medium sm:mt-0 mx-auto">
            Designed By Crystal Streak
            </p>
        </ul>

      </div>
    </footer>
  );
};

export default Footer;
